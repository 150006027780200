section.aboutsection {
    background: #131313;
    padding-top: 20px;
    padding-bottom: 120px;
}

section.aboutsection .row {
    align-items: center;
}

img.aboutimg {margin: auto;display: block;}

h2.abouth2 {
    color: white;
    font-family: 'Syne';
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    margin-bottom: 20px;
}

h2.abouth2 span {color: #828DF8;}

section.aboutsection p {
    color: white;
    font-size: 16px;
    font-family: 'Syne';
    margin-bottom: 50px;
}

a.aboutbtn {
    text-align: center;
    background: #443ACF;
    text-decoration: none;
    color: white;
    padding: 10px 30px;
    border-radius: 5px;
    font-family: 'Syne';
    font-size: 18px;
    padding-bottom: 12px;
    letter-spacing: 1px;
    z-index: 99;
    position: relative;
}

.aboutidv:before {
    content: '';
    width: 112%;
    height: 112%;
    background: url(../assets/images/connect-circle-background.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    z-index: 1;
}

img.aboutimg {
    max-width: 80%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    display: block;
}

.aboutidv:after {
    content: '';
    padding: 90%;
    position: absolute;
    top: 56%;
    left: 50%;
    background: url(../assets/images/circle-bg.webp);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    /* background-image: radial-gradient(rgb(71 61 213) 0%, rgb(71 61 213) 0%, rgba(19, 19, 19, 0) 61%); */
    border-radius: 100%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
}

.aboutidv {
    position: relative;
    width: 100%;
}