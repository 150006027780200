section.testimonialsec {
    background: #131313;
    padding-top: 0px;
    padding-bottom: 120px;
}

section.testimonialsec .row {
    align-items: center;
}

h2.testimonialsech2 {
    color: white;
    font-family: 'Syne';
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 999;
}

h2.testimonialsech2 span {color: #828DF8;}

section.testimonialsec p {
    color: white;
    font-size: 16px;
    font-family: 'Syne';
    text-align: center;
    margin-bottom: 40px !important;
    width: 80%;
    display: block;
    margin: auto;
    position: relative;
    z-index: 999;
}

section.testimonialsec strong {
    color: #473DD5;
    font-size: 20px;
}

.reviews-list {
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    display: flex;
    margin-top: 65px !important;
    position: relative;
    z-index: 999;
}

/* .reviews-list .review {
    width: calc(33.33% - 79px);
} */

.reviews-list .review {
    width: 32.23%;
} 

section.testimonialsec .boxed {
    background: #212121;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid #282727;
}

.reviews-list .review .title {
    font-size: 18px;
    font-family: 'Syne';
    color: #ffffff;
    margin-bottom: 15px;
    line-height: 1.3;
    font-weight: 600;
}

.reviews-list .review .testimonial {
    line-height: 1.5;
    margin-bottom: 20px;
    color: #a1a1aa;
    font-family: 'Syne';
}

.infobx {
    align-items: flex-end;
    display: flex;
}

.reviews-list .review .source {
    color: #ccc;
}

.reviews-list .review .source .source-name {
    font-size: 14px;
    font-family: 'Syne';
    display: flex;
    align-items: center;
}

.reviews-list .review .source .source-name span {
    margin-left: 5px;
    margin-top: 4px;
}

.reviews-list .review .source .rating {
    line-height: 1;
    margin-top: 4px;
    white-space: nowrap;
    gap: 5px;
    display: flex;
}

.reviews-list .review .author {
    margin-left: auto;
    align-items: end;
    display: flex;
}

.reviews-list .review .author .name {
    color: #ccc;
    line-height: 1;
    text-align: right;
    font-family: 'Syne';
}

.reviews-list .review .author .thumbnail {
    margin-left: 10px;
    line-height: 1;
}

.reviews-list .review .author .thumbnail img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}

section.testimonialsec .container {
    position: relative;
}

section.testimonialsec .circle-bg {
    left: 0;
    top: 100%;
}