section.footersec {
    padding: 15px 0px;
    background: #131313;
    border-top: 1px solid rgb(33 33 33);
}

p.footertxt svg {
    margin-left: 10px !important;
    margin-top: 0px;
}

p.footertxt {
    color: white;
    margin-bottom: 0px;
    line-height: 15px;
    font-family: 'Syne';
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

section.footersec .row {
    align-items: center;
}

section.footersec img {
    width: 290px;
    height: 22px;
    margin-top: -3px;
}

.socialicon {
    flex-direction: row;
    display: flex;
    justify-content: end;
    gap: 20px;
}

.socialicon svg {
    margin: 0px !important;
}