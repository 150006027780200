section.bannersec {
    background: #131313;
    padding: 120px 0px;
    position: relative;
}

section.bannersec h2 {
    text-align: center;
    color: white;
    font-family: 'Syne';
    font-size: 70px;
    font-weight: 700;
    line-height: 75px;
    position: relative;
    z-index: 99;

}

section.bannersec span {
    color: #828DF8;
}

section.bannersec p {
    text-align: center;
    color: white;
    font-size: 19px;
    font-family: 'Syne';
    margin-top: 40px;
    position: relative;
    z-index: 99;

}

a.videobtn {
    text-align: center;
    margin: auto;
    color: #443ACF;
    background: white;
    width: 5rem;
    height: 5rem;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 9999px;
}

.videodiv {
    text-align: center;
    margin: auto;
    color: #443ACF;
    background: hsla(0,0%,100%,.5);
    width: 7rem;
    height: 7rem;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 9999px;
    margin-top: 55px;
    position: relative;
    z-index: 99;
}

a.videobtn svg {width: 2rem;height: 2rem;margin-left: .25rem;}

ul.bannerbtn {
    margin-bottom: 0px;
    display: inline-flex;
    list-style: none;
    text-align: center;
    padding: 0px;
    gap: 15px;
    position: relative;
    z-index: 99;
}

ul.bannerbtn a {
    text-align: center;
    background: #443ACF;
    text-decoration: none;
    color: white;
    padding: 10px 22px;
    border-radius: 5px;
    font-family: 'Syne';
    font-size: 18px;
    padding-bottom: 12px;
}

ul.bannerbtn li {
    line-height: 16px;
}

a.bansign {
    background: #27272A !important;
    padding: 10px 35px !important;
    padding-bottom: 12px !important;
}

.bannerbtndiv {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    position: relative;
    z-index: 99;
    margin-bottom: 20px;
}

.bannerimg {
    width: 100%;
    position: absolute;
    top: auto;
    bottom: 0%;
    max-height: 800px;
    z-index: 1;
}