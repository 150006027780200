section.aisection {
    background: #131313;
    padding-top: 0px;
    padding-bottom: 100px;
}

section.aisection .row {
    align-items: center;
}

h2.aisech2 {
    color: white;
    font-family: 'Syne';
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 999;
}

h2.aisech2 span {color: #828DF8;}

section.aisection p {
    color: white;
    font-size: 16px;
    font-family: 'Syne';
    text-align: center;
    margin-bottom: 40px !important;
    width: 80%;
    display: block;
    margin: auto;
    position: relative;
    z-index: 999;
}

a.aisecbtn {
    text-align: center;
    background: #443ACF;
    text-decoration: none;
    color: white;
    padding: 10px 30px;
    border-radius: 5px;
    font-family: 'Syne';
    font-size: 18px;
    padding-bottom: 12px;
    letter-spacing: 1px;
    z-index: 99;
    position: relative;
}

.aisecbtd {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 999;
}

.aisecbx {
    background: #212121;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid #282727;
    margin-bottom: 50px !important;
    width: 1200px;
    display: block;
    margin: auto;
    position: relative;
    z-index: 999;
}

h2.brandsh2.graphh2.aisech2 {
    font-size: 35px;
    line-height: 30px;
}

.table.boxed {
    background: #212121;
    padding: 0px;
    border-radius: 10px;
    border: 1px solid #282727;
    margin-bottom: 50px !important;
    width: 1100px;
    display: block;
    margin: auto;
    position: relative;
    z-index: 999;
}

.table.boxed table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    min-width: 380px;
    color: #ccc;
}

.table.boxed table th, table td {
    padding: 15px 20px;
}

.table.boxed table th {
    text-align: left;
    line-height: 1.3;
    color: #fff;
}

.table.boxed table tbody tr {
    border-top: 1px solid #282727;
    border-bottom: 0px;
}

.table.boxed table th, table td {
    padding: 15px 20px;
    color: white;
    font-family: 'Syne';
}

.table.boxed tr {
    background: transparent !important;
}

.table.boxed tr {
    border-bottom: 0px;
}

.table.boxed td svg {
    width: 20px;
    height: 15px;
}

.table.boxed thead th {padding-left: 23px;}