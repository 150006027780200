section.topbarsec {
    padding: 15px 0px;
    background: linear-gradient(55deg, rgba(0,0,0,1) 0%, rgba(79,70,229,1) 100%);
    border-bottom: 1px solid rgba(67,56,202,.5);
}

p.topbartxt svg {
    margin-left: 10px !important;
    margin-top: 0px;
}

p.topbartxt {
    text-align: center;
    color: white;
    margin-bottom: 0px;
    line-height: 16px;
    font-family: 'Syne';
    font-size: 17px;
    font-weight: 600;
    display: flex;
    justify-content: center;
}