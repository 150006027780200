section.pricingsec {
    background: #131313;
    padding-top: 0px;
    padding-bottom: 100px;
    position: relative;
}

section.pricingsec .row {
    align-items: center;
}

h2.pricingh2 {
    color: white;
    font-family: 'Syne';
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 999;
}

h2.pricingh2 span {color: #828DF8;}

section.pricingsec p {
    color: white;
    font-size: 16px;
    font-family: 'Syne';
    text-align: center;
    margin-bottom: 40px !important;
    width: 80%;
    display: block;
    margin: auto;
}

ul.mb-3.nav.nav-tabs {
    margin-bottom: 40px !important;
    justify-content: center;
    background: #212121;
    border: 1px solid #282727;
    border-radius: 10px;
    padding: 10px 10px;
    width: max-content;
    gap: 15px;
    margin-top: 50px !important;
    margin: 0 auto;
}

ul.mb-3.nav.nav-tabs li button {
    font-family: 'Syne';
    color: #ffffff;
    padding: 15px 20px;
    min-width: 115px;
    background: transparent;
    border: 0;
    font-size: 18px !important;
    border-radius: 5px;
    font-weight: 700;
}

ul.mb-3.nav.nav-tabs li button.nav-link.active {
    background: #473dd5;
}

/* .plan-list {
    margin-top: 60px;
    align-items: center;
    display: flex;
}

.plan-list .plan {
    width: 33.33%;
    padding: 40px 30px;
    box-sizing: border-box;
    background: #212121;
    border-radius: 10px;
    border: 1px solid #282727;
}

.plan-list .plan .plan-name {
    font-size: 30px;
    font-family: Inter-SemiBold;
    line-height: 1;
    color: #ffffff;
}

.plan-list .plan .price {
    font-size: 60px;
    color: #473dd5;
    font-family: Inter-Bold;
    line-height: 1;
}

.plan-list .plan .discounted-price {
    font-size: 22px;
    color: #4e4e4e;
    text-decoration: line-through;
    line-height: 1;
}

.plan-list .plan .label {
    font-size: 18px;
    color: #fff;
}

.plan-list .plan .excerpt {
    color: #555555;
    margin: 30px 0;
}

.plan-list .text-white {
    color: #ffffff;
}

.plan-list .plan .words-amount {
    font-size: 30px;
    font-family: Inter-Bold;
    color: #fff;
    line-height: 1.2;
}

.plan-list .plan .words-label {
    font-size: 14px;
    margin-bottom: 30px;
    color: #fff;
}

.plan-list .plan .check-list, .plan-list .plan .un-check-list {
    text-align: left;
    font-size: 14px;
    color: #fff;
}

.plan-list .plan .check-list li, .plan-list .plan .un-check-list li {
    position: relative;
    padding-left: 35px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.plan-list .plan .check-list li svg, .plan-list .plan .un-check-list li svg {
    position: absolute;
    top: 2px;
    left: 0;
    padding: 5px;
    border-radius: 100%;
    font-size: 10px;
}

.plan-list .divider {
    border-bottom: 1px solid #ffffff;
    margin: 20px 0;
}

.plan-list .plan .check-list, .plan-list .plan .un-check-list {
    text-align: left;
    font-size: 14px;
    color: #fff;
}

.plan-list .plan .check-list li, .plan-list .plan .un-check-list li {
    position: relative;
    padding-left: 35px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.plan-list .plan .check-list li svg, .plan-list .plan .un-check-list li svg {
    position: absolute;
    top: 2px;
    left: 0;
    padding: 5px;
    border-radius: 100%;
    font-size: 10px;
}

.plan-list .plan .upgrade {
    display: flex;
    margin: 30px 0;
}

.plan-list .plan .upgrade button {
    background: #3b3b3b;
    border: 0;
    width: 22px;
    height: 22px;
    min-width: auto;
    padding: 0;
    border-radius: 5px;
}

.plan-list .plan .upgrade label {
    width: 80%;
    flex-grow: 1;
    text-align: left;
    padding-left: 12px;
    line-height: 1.4;
    color: #fff;
}

.plan-list .plan .action-button button {
    width: 100%;
    background: #3b3b3b;
    border: 0;
    padding: 15px 30px;
} */

.plan-list {
    margin-top: 130px;
    align-items: center;
    display: flex;
    margin-bottom: 70px;
}

.plan-list .divider {
    border-bottom: 1px solid #ffffff;
    margin: 20px 0;
}

.plan-list .plan {
    width: 33.33%;
    padding: 40px 30px;
    box-sizing: border-box;
    background: #212121;
    border-radius: 10px;
    border: 1px solid #282727;
}

.plan-list .plan .plan-name {
    font-size: 35px;
    font-family: 'Syne';
    line-height: 1;
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    margin-top: 0px;
}

.plan-list .text-white {
    color: #ffffff !important;
    margin-top: 15px;
}

.plan-list .plan .price {
    font-size: 60px;
    color: #473dd5;
    font-family: 'Syne';
    line-height: 1;
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
}

.plan-list .plan .discounted-price {
    font-size: 28px;
    color: #4e4e4e;
    text-decoration: line-through;
    line-height: 1;
    text-align: center;
    font-family: 'Syne';
    margin-top: 10px;
}

.plan-list .plan .label {
    font-size: 20px;
    color: #fff;
    text-align: center;
    font-family: 'Syne';
    font-weight: 500;
    margin-top: 20px;
}

.plan-list .plan .excerpt {
    color: #555555;
    margin: 30px 0;
    text-align: center;
    font-family: 'Syne';
    font-size: 17px;
}

.plan-list .text-white {
    color: #ffffff !important;
    margin-top: 15px;
}

.plan-list .plan .words-amount {
    font-size: 33px;
    font-family: 'Syne';
    color: #fff;
    line-height: 1.2;
    text-align: center;
    font-weight: 600;
}

.plan-list .plan .words-label {
    font-size: 16px;
    margin-bottom: 30px;
    color: #fff;
    text-align: center;
    font-family: 'Syne';
    margin-top: 10px;
}

.plan-list .plan ul.check-list {
    list-style: none;
    padding: 0px;
}

.plan-list .plan .check-list, .plan-list .plan .un-check-list {
    text-align: left;
    font-size: 15px;
    color: #fff;
    list-style: none;
    padding: 0px;
}

.plan-list .plan .check-list li, .plan-list .plan .un-check-list li {
    position: relative;
    padding-left: 35px;
    line-height: 1.5;
    margin-bottom: 10px;
    font-family: 'Syne';
    font-size: 16px;
}

.plan-list .plan .check-list li svg, .plan-list .plan .un-check-list li svg {
    position: absolute;
    top: 0px;
    left: 0;
    padding: 5px;
    border-radius: 100%;
    font-size: 22px;
    background: #473DD5;
}

.plan-list .plan .check-list, .plan-list .plan .un-check-list {
    text-align: left;
    font-size: 15px;
    color: #fff;
    list-style: none;
    padding: 0px;
}

.plan-list .plan .upgrade {
    display: flex;
    margin: 30px 0;
}

.plan-list .plan .upgrade button {
    background: #3b3b3b;
    border: 0;
    width: 22px;
    height: 22px;
    min-width: auto;
    padding: 0;
    border-radius: 5px;
}

.plan-list .plan .upgrade label {
    width: 80%;
    flex-grow: 1;
    text-align: left;
    padding-left: 12px;
    line-height: 25px;
    color: #fff;
    margin-top: -3px;
    font-family: 'Syne';
}

.plan-list .plan .action-button button {
    width: 100%;
    background: #3b3b3b;
    border: 0;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    color: white;
    border-radius: 5px;
    font-family: 'Syne';
    font-size: 18px;
    padding-bottom: 12px;
    letter-spacing: 1px;
    z-index: 99;
    position: relative;
    font-weight: 600;
}

.plan.boxed.samesame .excerpt {
    height: 168px;
}

.plan.boxed.samesame ul.check-list {
    height: 185px;
}

.plan-list .plan.recommended {
    background: #473dd5;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    position: relative;
    box-shadow: 1px 0 43px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 1px 0 43px rgba(0, 0, 0, 0.25);
    border: 0;
}

.plan-list .plan.recommended .price {
    color: white;
}

.plan-list .plan.recommended .discounted-price {
    color: #837aff;
}

.plan-list .plan.recommended .excerpt {
    color: #8b82ff;
}

.plan-list .plan.recommended .action-button button {
    background: white;
    color: #3b3b3b;
    font-weight: 600;
}

.plan-list .plan.recommended .un-check-list li svg {
    background: #212121;
}

.plan-list .plan.recommended ul.check-list svg {
    background: #5f56da;
    border: 1px solid #473dd5;
}

.pricingsec .circle-bg {
    left: auto;
    right: -1000px;
    top: -50%;
    transform: unset;
    -webkit-transform: unset;
}

.tabbtn {
    position: relative;
    z-index: 999;
}