section.ad-banner {
    background: #131313;
    padding-bottom: 120px;
}

section.ad-banner .ad-banner {
    overflow: hidden;
    position: relative;
    background: #473dd5;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid #282727;
    display: flex;
}

section.ad-banner .ad-banner .text {
    padding: 4%  0 4% 5%;
    position: relative;
    z-index: 1;
    width: 50%;
}

section.ad-banner .ad-banner h2 {
    font-family: 'Syne';
    font-size: 34px;
    color: white;
    font-weight: 600;
    margin-top: -7px;
}

section.ad-banner .ad-banner .text .description {
    color: #fff;
    margin: 20px 0 25px 0px;
    font-family: 'Syne';
    font-size: 18px;
}

section.ad-banner .ad-banner .text .sm-text {
    color: #b8e0ff;
    margin-top: 25px;
    font-family: 'Syne';
    font-size: 18px;
    margin-bottom: -3px;
}

section.ad-banner .thumbnail {
    width: 50%;
}

section.ad-banner .ad-banner .thumbnail img {
    position: absolute;
    top: 0;
    right: 8%;
    object-fit: cover;
    object-position: center;
    height: 100%;
}

.cta-button button {
    text-align: center;
    background: #131313;
    text-decoration: none;
    color: white;
    padding: 10px 30px;
    border-radius: 5px;
    font-family: 'Syne';
    font-size: 18px;
    padding-bottom: 10px;
    letter-spacing: 1px;
    border: 0px;
}

section.ad-banner .ad-banner .text .sm-text a {
    color: #b8e0ff;
}