section.brandssec {
  background: #131313;
  padding-top: 20px;
  padding-bottom: 100px;
}

section.brandssec .row {
  align-items: center;
}

h2.brandsh2 {
  color: white;
  font-family: "Syne";
  font-size: 50px;
  font-weight: 700;
  line-height: 65px;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  z-index: 999;
}

h2.brandsh2 span {
  color: #828df8;
}

section.brandssec p {
  color: white;
  font-size: 16px;
  font-family: "Syne";
  text-align: center;
  margin-bottom: 40px !important;
  width: 80%;
  display: block;
  margin: auto;
  position: relative;
  z-index: 999;
}

a.brandsbtn {
  text-align: center;
  background: #443acf;
  text-decoration: none;
  color: white;
  padding: 10px 30px;
  border-radius: 5px;
  font-family: "Syne";
  font-size: 18px;
  padding-bottom: 12px;
  letter-spacing: 1px;
  z-index: 99;
  position: relative;
}

.brandbtd {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 999;
}

.featured-logos img {
  width: 153px;
  height: 53px;
  object-fit: cover;
}

.featured-logos ul {
  display: flex;
  list-style: none;
  padding: 0px;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 0px;
}

.featured-logos ul li {
  width: calc(20% - 16px);
}

.featured-logos ul li a {
  display: block;
  max-width: 80%;
  margin: 0 auto;
}

.featured-logos {
  background: #212121;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #282727;
  margin-bottom: 50px !important;
  width: 1100px;
  display: block;
  margin: auto;
  position: relative;
  z-index: 999;
}

.featured-logos ul li img {
  filter: brightness(0) invert(1);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.graphbx {
  background: #212121;
  padding: 55px 30px;
  border-radius: 10px;
  border: 1px solid #282727;
  margin-bottom: 50px !important;
  width: 1100px;
  display: block;
  margin: auto;
  position: relative;
  z-index: 999;
}

h2.brandsh2.graphh2 {
  font-size: 35px;
  line-height: 30px;
}

#graphtable {
  height: 325px;
  max-width: 680px;
  margin: 0 auto;
  margin-top: 65px !important;
}

.graphtr {
  border: 0px !important;
}

tr.graphtr {
  padding: 10px 20px !important;
  padding-bottom: 0px !important;
}

tr.graphtr:hover {
  background: #4242426e;
  border-radius: 10px 10px 0px 0px;
}

table#graphtable th {
  color: white;
  font-family: 'Syne';
  font-size: 18px;
  margin-bottom: -55px;
}

.graphtd1 {
  --size: 0.8 !important;
  border: 0px solid white !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  height: 250px !important;
  border-radius: 5px 5px 0px 0px !important;
  background: #d4dcff !important;
}

.graphtd2 {
  --size: 0.5 !important;
  border: 5px solid #212121 !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  height: 175px !important;
  border-radius: 8px 8px 0px 0px !important;
  background: #1AFFD5 !important;
}

.graphtd3 {
  --size: 0.9 !important;
  border: 0px solid white !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  height: 265px !important;
  border-radius: 5px 5px 0px 0px !important;
  background: #6366F1 !important;
}

.graphtd4 {
  --size: 0.8 !important;
  border: 0px solid white !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  height: 185px !important;
  border-radius: 5px 5px 0px 0px !important;
  background: #D4DCFF !important;
}

.graphtd5 {
  --size: 0.5 !important;
  border: 5px solid #212121 !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  height: 190px !important;
  border-radius: 8px 8px 0px 0px !important;
  background: #1AFFD5 !important;
}

.graphtd6 {
  --size: 0.9 !important;
  border: 0px solid white !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  height: 175px !important;
  border-radius: 5px 5px 0px 0px !important;
  background: #6366F1 !important;
}

.graphtd7 {
  --size: 0.8 !important;
  border: 0px solid white !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  height: 235px !important;
  border-radius: 5px 5px 0px 0px !important;
  background: #D4DCFF !important;
}

.graphtd8 {
  --size: 0.5 !important;
  border: 5px solid #212121 !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  height: 15px !important;
  background: #1AFFD5 !important;
  border-radius: 8px 8px 0px 0px !important;
}

.graphtd9 {
  --size: 0.9 !important;
  border: 0px solid white !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  height: 215px !important;
  border-radius: 5px 5px 0px 0px !important;
  background: #6366F1 !important;
}

.graphtd10 {
  --size: 0.8 !important;
  border: 0px solid white !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  height: 155px !important;
  border-radius: 5px 5px 0px 0px !important;
  background: #D4DCFF !important;
}

.graphtd11 {
  --size: 0.5 !important;
  border: 5px solid #212121 !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  background: #1AFFD5 !important;
  height: 20px !important;
  border-radius: 8px 8px 0px 0px !important;
}

.graphtd12 {
  --size: 0.9 !important;
  border: 0px solid white !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  height: 160px !important;
  background: #6366F1 !important;
  border-radius: 5px 5px 0px 0px !important;
}

ul.percentgrapg {
  padding: 0px;
  list-style: none;
  position: absolute;
  margin-bottom: 0px;
  margin-top: -5px;
  margin-left: 160px;
}

ul.percentgrapg li {
  color: white;
  font-family: 'Syne';
  font-size: 19px;
  margin-bottom: 35px;
}

ul.instgraph {
  padding: 0px;
  list-style: none;
  display: flex;
  gap: 25px;
  justify-content: center;
  margin-bottom: 0px;
}

ul.instgraph li {
  color: white;
  font-family: 'Syne';
  font-size: 19px;
}

.instuldiv {
  display: flex;
  justify-content: center;
}

.graphbdiv {
  margin-top: -40px;
  margin-right: -85px;
}

li.recharts-legend-item svg {
  margin-right: 10px;
  margin-top: -1px;
}

.tooltip {
  position: absolute;
  z-index: 1020;
  display: block;
  visibility: visible;
  padding: 5px;
  font-size: 13px;
  opacity: 0;
  filter: alpha(opacity=0);
  margin-top: 50px;
}

.tooltip.in {
  opacity: .9;
  filter: alpha(opacity=80)
}

.tooltip-inner {
  /*width: 350px;*/
  padding: 3px 8px;
  color: #808080;
  text-align: center;
  background: #FFF;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #314A5B;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0
}

.circle-bg {
  width: 2000px;
  height: 2000px;
  background: url(../assets/images/circle-bg.webp);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  /* background-image: radial-gradient(rgb(71 61 213) 0%, rgb(71 61 213) 0%, rgba(19, 19, 19, 0) 58%); */
  border-radius: 100%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.5;
}

section.brandssec .container {
  position: relative;
}