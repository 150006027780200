.webheader {
    background: #131313 !important;
    padding: 25px 0px;
    padding-top: 23px;
}

a.navbar-brand {
    font-family: 'Syne', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 20px;
    align-items: center;
    display: flex;
}

a.navbar-brand span {
    font-size: 14px;
    background: #473CD3;
    padding: 5px 25px;
    border-radius: 50px;
    border: 0px !important;
    margin-left: 20px;
}

a.headerbtn {
    font-family: 'Syne', sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 17px;
}

a.headerbtn.signupbtn {
    background: white;
    color: #473dd5 !important;
    border-radius: 5px;
    padding: 13px 30px !important;
}

a.headerbtn.extensionbtn {
    background: #473dd5;
    color: white;
    margin-left: 10px;
    border-radius: 5px;
    padding: 13px 35px !important;
}

img.logohead {
    margin-bottom: -1px;
    width: 90%;
}