section.faqsection {
    background: #131313;
    padding-top: 20px;
    padding-bottom: 120px;
}

section.faqsection .row {
    align-items: start;
}

.faqhead {
    font-size: 35px;
    line-height: 30px;
}

.faqhead {
    color: white;
    font-family: 'Syne';
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    margin-bottom: 20px;
    margin-top: -15px;
}

.faqpara {
    color: white;
    font-size: 20px;
    font-family: 'Syne';
    margin-bottom: 50px !important;
    margin-top: 30px !important;
}

a.faqbtn {
    text-align: center;
    background: #443ACF;
    text-decoration: none;
    color: white;
    padding: 10px 30px;
    border-radius: 5px;
    font-family: 'Syne';
    font-size: 18px;
    padding-bottom: 12px;
    letter-spacing: 1px;
    z-index: 99;
    position: relative;
}

button.accordion-button {
    background: #212121;
    color: white;
    font-family: 'Syne';
    font-size: 21px;
    font-weight: 600;
}

.accordion-button:not(.collapsed) {
    background: #212121;
    color: white;
    font-family: 'Syne';
    font-size: 21px;
    font-weight: 600;
    box-shadow: none;
}

.accordion {
    border: 0px !important;
    /* margin-right: 4px; */
}

.accordion-item {
    border: 0px !important;
    background: #212121;
    margin-bottom: 10px;
    border-radius: 10px;
}

h2.accordion-header {
    background: #212121;
    border-radius: 10px;
}

button.accordion-button {
    border-radius: 10px !important;
}

.accordion-body {
    font-family: 'Syne';
    color: #a1a1aa;
    padding-top: 0px !important;
    padding: 25px;
    font-size: 17px;
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0 !important;
    box-shadow: none !important;
    border: 0px !important;
}

button.accordion-button {
    padding: 25px;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
    filter: brightness(0) invert(1);
}

.accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
    filter: brightness(0) invert(1);
}

/* .accorddol {
    margin-left: 4px;
} */

.section.faqsection .circle-bg {
    left: 0;
    top: -10%;
}

.accorddol.col {
    position: relative;
    z-index: 999;
}

.accordion {
    position: relative;
    z-index: 999;
}